import { Axios } from '@/api/config';

const api_version = '/api/v1.0';

export const signupAsIndividualPerson = (user) => {
  const url = user.second_name
    ? `${api_version}/clients/web-site/auth/sign-up/as-individual?first_name=${user.first_name}&last_name=${user.last_name}&second_name=${user.second_name}&email=${user.email}&telephone_number=${user.telephone_number}&password=${user.password}`
    : `${api_version}/clients/web-site/auth/sign-up/as-individual?first_name=${user.first_name}&last_name=${user.last_name}&email=${user.email}&telephone_number=${user.telephone_number}&password=${user.password}`;
  return Axios.post(url);
};

export const signupAsLegalPerson = (user) => {
  const url = user.second_name
    ? `${api_version}/clients/web-site/auth/sign-up/as-legal-person?first_name=${user.first_name}&last_name=${user.last_name}&second_name=${user.second_name}&email=${user.email}&telephone_number=${user.telephone_number}&password=${user.password}&position=${user.position}&name_company=${user.name_company}&email_company=${user.email_company}&address=${user.address}&edrpou_code=G${user.edrpou_code}G&telephone_number_company=${user.telephone_number_company}&country_registration_id=${user.country_registration_id}`
    : `${api_version}/clients/web-site/auth/sign-up/as-legal-person?first_name=${user.first_name}&last_name=${user.last_name}&email=${user.email}&telephone_number=${user.telephone_number}&password=${user.password}&position=${user.position}&name_company=${user.name_company}&email_company=${user.email_company}&address=${user.address}&edrpou_code=G${user.edrpou_code}G&telephone_number_company=${user.telephone_number_company}&country_registration_id=${user.country_registration_id}`;
  return Axios.post(url);
};

export const login = (data) => {
  const url = `${api_version}/clients/web-site/auth/sign-in`;
  return Axios.post(url, data);
};

export const refreshToken = () => {
  const url = `${api_version}/clients/web-site/auth/refresh`;
  return Axios.post(url);
};

export const resetPassword = (data) => {
  const url = `${api_version}/clients/web-site/auth/reset-password`;
  return Axios.post(url, data);
};

export const logout = () => {
  const url = `${api_version}/clients/web-site/auth/logout`;
  return Axios.post(url);
};

export const allAvailableCustoms = () => {
  const url = `${api_version}/web-site/available-customs`;
  return Axios.get(url, {
    headers: {
      'Accept-Language': 'uk',
    },
  });
};

export const allCarBrands = () => {
  const url = `${api_version}/common/cars/brands`;
  return Axios.get(url);
};

export const allCarModels = (id) => {
  const url = `${api_version}/common/cars/models?car-brand-id=${id}`;
  return Axios.get(url);
};

export const allCountries = () => {
  const url = `${api_version}/common/countries`;
  return Axios.get(url);
};

export const ticketPricing = (customsPost) => {
  const url = `${api_version}/web-site/tickets/pricing`;
  return Axios.get(url, {
    headers: {
      'Customs-Checkpoint': customsPost,
    },
  });
};

export const timeSlots = (data) => {
  const url = `${api_version}/web-site/tickets/buy/time-slots?date=${data.date}&type_auto=${data.typeAuto}`;
  return Axios.get(url, {
    headers: {
      'Customs-Checkpoint': data.customsPost,
    },
  });
};

export const holdSlot = (data) => {
  const url = `${api_version}/web-site/tickets/buy/execute-hold-selected-slot?datetime=${data.datetime}&type_auto=${data.typeAuto}`;
  return Axios.post(url, null, {
    headers: {
      'Customs-Checkpoint': data.customsPost,
    },
  });
};

export const registerNewTicket = (ticket) => {
  const url = ticket.second_name
    ? `${api_version}/web-site/tickets/buy/register-ticket-for-payment?first_name=${ticket.first_name}&last_name=${ticket.last_name}&second_name=${ticket.second_name}&email=${ticket.email}&telephone_number=${ticket.telephone_number}&type_auto=${ticket.type_auto}&country_registration_id=${ticket.country_registration_id}&auto_registration_plate=${ticket.auto_registration_plate}&car_brand_id=${ticket.car_brand_id}&car_model_id=${ticket.car_model_id}&count_of_passengers=${ticket.count_of_passengers}&manual_payment=${ticket.manual_payment}&time_interval=${ticket.time_interval}&datetime=${ticket.datetime}`
    : `${api_version}/web-site/tickets/buy/register-ticket-for-payment?first_name=${ticket.first_name}&last_name=${ticket.last_name}&email=${ticket.email}&telephone_number=${ticket.telephone_number}&type_auto=${ticket.type_auto}&country_registration_id=${ticket.country_registration_id}&auto_registration_plate=${ticket.auto_registration_plate}&car_brand_id=${ticket.car_brand_id}&car_model_id=${ticket.car_model_id}&count_of_passengers=${ticket.count_of_passengers}&manual_payment=${ticket.manual_payment}&time_interval=${ticket.time_interval}&datetime=${ticket.datetime}`;
  return Axios.post(url, null, {
    headers: {
      'Customs-Checkpoint': ticket.customs_post,
    },
  });
};

export const paymentStatus = (ticket) => {
  const url = `${api_version}/web-site/tickets/buy/payment-status?order_id=${ticket.order_id}`;
  return Axios.get(url, {
    headers: {
      'Customs-Checkpoint': ticket.customs_post,
    },
  });
};
