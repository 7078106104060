import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import { checkUserToken } from '@/api/config';

import VueI18n from '@/i18n';

Vue.use(VueRouter);
Vue.use(VueI18n);

const routes = [
  {
    path: '/',
    component: () => import('@/components/Main/Main.vue'),
    meta: {
      title: `${VueI18n.t('titles.main')}`,
    },
    children: [
      {
        path: '/',
        name: 'MainPage',
        component: () => import('@/components/MainPage/MainPage.vue'),
        meta: {
          title: `Головна сторінка`,
        },
      },
      {
        path: '/privacy',
        name: 'PrivacyPage',
        component: () => import('@/components/MainPage/PrivacyPage.vue'),
        meta: {
          title: `Політика конфеденційності`,
        },
      },
      {
        path: '/terms',
        name: 'TermsPage',
        component: () => import('@/components/MainPage/TermsPage.vue'),
        meta: {
          title: `Умови користування`,
        },
      },
      {
        path: '/sign-in',
        name: 'Auth',
        component: () => import('@/components/Auth/Auth.vue'),
        meta: {
          title: `Авторизація`,
        },
      },
      {
        path: '/sign-up',
        component: () => import('@/components/Signup/Signup.vue'),
        children: [
          {
            path: '',
            name: 'Signup',
            component: () => import('@/components/Signup/SignupType.vue'),
            meta: {
              title: `Реєстрація`,
            },
          },
          {
            path: 'individual-person',
            name: 'SignupIndividualPerson',
            component: () =>
              import(
                '@/components/Signup/SignupIndividual/SignupIndividualPerson.vue'
              ),
            meta: {
              title: `Фізична особа`,
            },
          },
          {
            path: 'legal-person',
            component: () =>
              import('@/components/Signup/SignupLegal/SignupLegal.vue'),
            children: [
              {
                path: '',
                name: 'SignupLegalPerson',
                component: () =>
                  import(
                    '@/components/Signup/SignupLegal/SignupLegalPerson.vue'
                  ),
                meta: {
                  title: `Юридична особа`,
                },
              },
              {
                path: 'contact',
                name: 'SignupLegalContactPerson',
                component: () =>
                  import(
                    '@/components/Signup/SignupLegal/SignupLegalContactPerson.vue'
                  ),
                meta: {
                  title: `Контактна особа`,
                },
              },
            ],
          },
          {
            path: 'status',
            name: 'SignupStatus',
            component: () => import('@/components/Signup/SignupStatus.vue'),
            meta: {
              title: `Реєстрація`,
            },
          },
        ],
      },
      {
        path: '/new-ticket',
        component: () => import('@/components/NewTicket/NewTicket.vue'),
        children: [
          {
            path: '',
            name: 'NewTicketCustomsPost',
            component: () =>
              import('@/components/NewTicket/NewTicketCustomsPost.vue'),
            meta: {
              title: `Пункт пропуску`,
            },
          },
          {
            path: 'info',
            name: 'NewTicketInfo',
            component: () => import('@/components/NewTicket/NewTicketInfo.vue'),
            meta: {
              title: `Інформація про ТЗ`,
            },
          },
          {
            path: 'date',
            name: 'NewTicketDate',
            component: () => import('@/components/NewTicket/NewTicketDate.vue'),
            meta: {
              title: `Дата та час заїзду`,
            },
          },
          {
            path: 'payment-choice',
            name: 'NewTicketPaymentChoice',
            component: () =>
              import('@/components/NewTicket/NewTicketPaymentChoice.vue'),
            meta: {
              title: `Оплата`,
            },
          },
        ],
      },
      {
        path: 'payment-status',
        name: 'PaymentStatus',
        component: () => import('@/components/PaymentStatus/PaymentStatus.vue'),
        meta: {
          title: `Статус оплати`,
        },
      },
      {
        path: '/:notFound(.*)*',
        name: 'NotFound',
        component: () => import('@/components/NotFound/NotFound.vue'),
        meta: {
          title: `${VueI18n.t('titles.not_found')}`,
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const publicRoutes = [
    'Auth',
    'Signup',
    'MainPage',
    'PrivacyPage',
    'TermsPage',
    'NotFound',
  ];

  const isPublicRoute = publicRoutes.some((routeName) =>
    to.name.includes(routeName)
  );

  if (!isPublicRoute && !checkUserToken()) {
    store.commit('LOGOUT_USER');
    next({ name: 'Auth' });
  } else {
    next();
  }
});

router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title =
      to.meta && to.meta.title
        ? `${to.meta.title} | ${process.env.VUE_APP_TITLE}`
        : process.env.VUE_APP_TITLE;
  });
});

export default router;
