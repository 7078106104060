import Vue from 'vue';
import Vuex from 'vuex';
import router from '@/router';
import {
  signupAsIndividualPerson,
  signupAsLegalPerson,
  login,
  refreshToken,
  resetPassword,
  logout,
  allAvailableCustoms,
  allCarBrands,
  allCarModels,
  allCountries,
  ticketPricing,
  timeSlots,
  holdSlot,
  registerNewTicket,
  paymentStatus,
} from '@/api/services';

import { setToLS, getFromLS, isLSHasItem } from '@/library/helpers';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    client: isLSHasItem('client') ? getFromLS('client') : null,
    token: isLSHasItem('token') ? getFromLS('token') : null,
    legalPersonInfo: null,
    notification: null,
    // locale: {
    //   title: 'Укр',
    //   value: 'uk',
    // },
  },

  getters: {
    isAuthUser: (state) => !!state.token,
    getUser: (state) => state.client,
    getLegalPersonInfo: (state) => state.legalPersonInfo,
    getNotification: (state) => state.notification,
    // getLocale: (state) => state.locale,
  },

  mutations: {
    SAVE_USER(state, payload) {
      const { token, client } = payload;
      state.client = client;
      state.token = token;
      setToLS('token', token);
      setToLS('client', client);
    },
    LOGOUT_USER(state) {
      state.client = null;
      state.token = null;
      localStorage.clear();
    },
    updateLegalPersonInfo(state, payload) {
      state.legalPersonInfo = payload;
    },
    setNotification(state, notification) {
      state.notification = notification;
    },
    clearNotification(state) {
      state.notification = null;
    },
    // setLocale(state, locale) {
    //   state.locale = locale;
    // },
  },

  actions: {
    setNotification({ commit, getters }, notification) {
      commit('setNotification', notification);
      setTimeout(() => {
        if (getters.getNotification) {
          commit('clearNotification');
        }
      }, 5000);
    },
    async authorization({ commit }, data) {
      let res;

      try {
        res = await login(data);
        const { access_token: token, client } = res.data.data;

        commit('SAVE_USER', {
          token,
          client,
        });

        router.push({ name: 'NewTicketCustomsPost' });
      } catch (error) {
        const statusCode = error.response ? error.response.status : null;

        if (statusCode === 401) {
          return error.response.data.status;
        }

        error.handleGlobally && error.handleGlobally();
      }
    },
    async resetPassword(_, data) {
      try {
        await resetPassword(data);
      } catch (error) {
        const statusCode = error.response ? error.response.status : null;
        const isNotExistingEmail = error.response
          ? error.response.data.errors.email[0] ===
            'The selected email is invalid.'
          : false;

        if (statusCode === 422 && isNotExistingEmail) {
          return error.response.data.errors.email[0];
        }

        error.handleGlobally && error.handleGlobally();
      }
    },
    async userLogout({ commit }) {
      try {
        await logout();
        commit('LOGOUT_USER');
        router.push({ name: 'Auth' });
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },
    async signupIndividualPerson({ commit }, user) {
      let res;

      try {
        res = await signupAsIndividualPerson(user);
        const { access_token: token, client } = res.data.data;

        commit('SAVE_USER', {
          token,
          client,
        });

        router.push({ name: 'SignupStatus' });
      } catch (error) {
        const statusCode = error.response ? error.response.status : null;
        const isExistingEmail = error.response
          ? error.response.data.errors.email[0] ===
            'The email has already been taken.'
          : false;

        if (statusCode === 422 && isExistingEmail) {
          return error.response.data.errors.email[0];
        }

        error.handleGlobally && error.handleGlobally();
      }
    },
    async signupLegalPerson({ commit }, user) {
      let res;

      try {
        res = await signupAsLegalPerson(user);
        const { access_token: token, client } = res.data.data;

        commit('SAVE_USER', {
          token,
          client,
        });

        router.push({ name: 'SignupStatus' });
      } catch (error) {
        const statusCode = error.response ? error.response.status : null;
        const isExistingEmail = error.response
          ? error.response.data.errors.email[0] ===
            'The email has already been taken.'
          : false;

        if (statusCode === 422 && isExistingEmail) {
          return error.response.data.errors.email[0];
        }

        error.handleGlobally && error.handleGlobally();
      }
    },
    async getAllAvailableCustoms() {
      try {
        const res = await allAvailableCustoms();
        return res.data.data.customs;
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },
    async getTicketPricing(_, customsPost) {
      try {
        const res = await ticketPricing(customsPost);
        return res.data.data.pricing;
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },
    async getAllCarBrands() {
      try {
        const res = await allCarBrands();
        return res.data.data.brands;
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },
    async getAllCarModels(_, id) {
      try {
        const res = await allCarModels(id);
        return res.data.data.models;
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },
    async getAllCountries() {
      try {
        const res = await allCountries();
        return res.data.data.countries;
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },
    async getTimeSlots(_, data) {
      try {
        const res = await timeSlots(data);
        return res.data.data.time_slots;
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },
    async executeHoldSlot(_, data) {
      try {
        await holdSlot(data);
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },
    async registerNewTicket(_, ticket) {
      try {
        const res = await registerNewTicket(ticket);
        const newTicket = res.data.data;
        console.log(newTicket);

        return newTicket;
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },
    async getPaymentStatus(_, ticket) {
      try {
        const res = await paymentStatus(ticket);
        return res.data.data;
      } catch (error) {
        error.handleGlobally && error.handleGlobally();
      }
    },
  },
  modules: {},
});
