<template>
  <div id="app" data-app>
    <router-view />
  </div>
</template>

<script>
export default {};
</script>

<style lang="sass">
@import '@/assets/styles/main.sass'
</style>
